<template>
    <el-dialog title="屏蔽词设置" :visible.sync="visibleDown" width="450px">
        <div class="shieldSetting">
            <div class="shieldInput flex  flexSpace">
                <el-input v-model="keyword" placeholder="请输入屏蔽词"></el-input>
                <el-button type="primary" @click="sureSetting">添加</el-button>
            </div>
            <div class="shieldContent flex flexWrap alignStart">
                <Tag v-for="tag in tags" :key="tag.type" closable :type="tag.label" effect="plain"
                    @close="closeMethod(tag)">
                    {{tag.label}}
                </Tag>
            </div>
            <p class="shieldTitle baseTitle shieldTitleMargin">温馨提示：此功能用于底部批量下载时,群资源拦截下载,您可以添加一些您不想加的群,这些词相关的群不会被下载和扣积分,关键词最多可添加10个。</p>
            <p class="shieldTitle baseTitle">举例说明,如果您添加了“宝妈”这个词,那么批量下载的时候所有和“宝妈”有关的群都不会被您下载。</p>
           
        </div>
    </el-dialog>
</template>
<script>
    import { mapState } from 'vuex';
    import api from '../../http/api';
    import { Tag, Message } from 'element-ui';
    export default {
        data() {
            return {
                visibleDown: false,
                keyword: '',//屏蔽关键词
                tags: [],
            }
        },
        components: {
            Tag
        },
        created() {
            this.init();
        },
        methods: {
            closeMethod(val) {
                console.log('关闭', val)
                this.tags = this.tags.filter(item => item.type != val.type)
                this.sureSetting(false)
            },
            async init() {
                let that = this;
                that.keyword='';
                let keyResult = await api.getKeyword({ type: 0 })
                that.tags = keyResult.code == 200 && Object.values(keyResult.response).length != 0 ? keyResult.response.keywords.map((item, index) => {
                    return {
                        type: index, label: item
                    }
                }) : []
                //console.log('keyword', keyResult)
            },
            sureSetting(isInput = true) {
                if (!this.keyword && isInput) {
                    Message({
                        message: '请输入下载屏蔽关键词~',
                        type: 'error'
                    });
                    return;
                }
                if (this.tags.findIndex(item => item.label == this.keyword) > -1 && isInput) {
                    Message({
                        message: '屏蔽词已存在~',
                        type: 'error'
                    });
                    return;
                }
                if(this.tags.length>=10){
                    Message({
                        message: '拦截关键词最多可添加10个~',
                        type: 'error'
                    });
                    return; 
                }
                let tags = this.tags.map(item => item.label);
                isInput && tags.push(this.keyword)
                api.setKeyword({
                    keywords: tags,
                    type: 0
                }).then(res => {
                    console.log('设置成功?', res)
                    isInput && Message({
                        message: `设置${res.code == 200 ? '成功' : '失败'}~`,
                        type: res.code == 200 ? 'success' : 'error'
                    });
                    if (res.code == 200 && isInput) {
                        this.keyword = ''
                        this.init()
                    }

                })
            }
        }
    }
</script>
<style lang="less" scoped>
    /deep/ .el-dialog {
        margin-top: 35vh !important;
    }

    /deep/ .el-dialog__body {
        padding: 0px 20px 20px 20px;

    }

    /deep/ .el-input__inner {
        height: 35px;
        line-height: 35px;
        font-size: 13px;

    }

    /deep/ .el-input {

        width: 80%;
    }

    /deep/ .el-button {
        padding: 10px 20px;
        font-size: 13px;

    }

    /deep/ .el-tag {

        margin: 4px;
    }

    .shieldContent {
        margin-top: 10px;
        /* max-height: 150px;
        overflow-y: auto; */
    }
    .shieldTitleMargin{
        margin-top:5px;
    }
    .shieldTitle{
        color:#999;
        line-height:25px;
    }
</style>